import axios from 'axios';
import prepareRequestOptions from '../utils/admin-apis.util';

const getListContacts = async (query) => {
  const options = prepareRequestOptions(
    `/admin/contacts/${query}`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export default getListContacts;
