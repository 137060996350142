import React from 'react';
import { Grid } from '@mui/material';
import AppLayout from '../../layout/app.layout';
import Counters from '../../components/reports/counters.component';
import WatchedSecondsChart from '../../components/reports/watched-seconds-chart.component';
import PAGE_TITLE_LIST from '../../config/page-title.config';
import { SHOW_REPORTS_OVERVIEW_VIDEOS } from '../../config/const.config';
import PieChart from '../../components/reports/piechart.component';

const DashboardView = () => {
  const pageTitle = PAGE_TITLE_LIST.OVERVIEW_DASHBOARD;

  return (
    <AppLayout pageTitle={pageTitle}>
      <Counters />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          {SHOW_REPORTS_OVERVIEW_VIDEOS && <WatchedSecondsChart />}
          <PieChart />
        </Grid>
      </Grid>
    </AppLayout>
  );
};

export default DashboardView;
