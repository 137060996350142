import LANGUAGE_CHARS from '../config/language.config';

const validateAssetLanguageFormat = (files) => {
  let errorMsg = '';

  for (let loop = 0; loop < files.length; loop += 1) {
    if (
      !LANGUAGE_CHARS.includes(files[loop]?.name?.split('-')[0].toLowerCase())
    ) {
      errorMsg = 'Incorrect filename format, rename to described format';
      break;
    }
  }

  return errorMsg || '';
};

const validateFileType = (allowedExtensions, files) => {
  let errorMsg = '';

  for (let loop = 0; loop < files.length; loop += 1) {
    if (!allowedExtensions.exec(files[loop]?.name)) {
      errorMsg = 'Invalid file type selected.';
    }
  }

  return errorMsg || '';
};

const validateSelectedVideoFile = (
  selectedFiles = null,
  multiUpload = false
) => {
  let errorMsg = '';
  const files = selectedFiles;

  if (!files || files?.length === 0) {
    errorMsg = 'Please select video file to upload.';
  } else if (!multiUpload && files?.length > 1) {
    errorMsg = 'Single video file selection allowed.';
  }

  if (errorMsg === '') {
    const allowedExtensions = /(\.mp4|\.mov|\.wmv|\.flv|\.avi|\.mkv|\.webm)$/i;
    errorMsg = validateFileType(allowedExtensions, files);
  }

  return errorMsg || null;
};

const validateSelectedImageFile = (
  selectedFiles = null,
  multiUpload = false
) => {
  let errorMsg = '';
  const files = selectedFiles;

  if (!files || files?.length === 0) {
    errorMsg = 'Please select image file to upload.';
  } else if (!multiUpload && files?.length > 1) {
    errorMsg = 'Single image file selection allowed.';
  }

  if (errorMsg === '') {
    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
    errorMsg = validateFileType(allowedExtensions, files);
  }

  return errorMsg || null;
};

const validateSelectedAudioFile = (
  selectedFiles = null,
  multiUpload = false
) => {
  let errorMsg = '';
  const files = selectedFiles;

  if (!files || files?.length === 0) {
    errorMsg = 'Please select audio file to upload.';
  } else if (!multiUpload && files?.length > 1) {
    errorMsg = 'Single audio file selection allowed.';
  } else if (multiUpload) {
    errorMsg = validateAssetLanguageFormat(selectedFiles);
  }

  if (errorMsg === '') {
    const allowedExtensions = /(\.mp3|\.aac|\.wav|\.flac)$/i;
    errorMsg = validateFileType(allowedExtensions, files);
  }

  return errorMsg || null;
};

const validateSelectedPdfFile = (selectedFiles = null, multiUpload = false) => {
  let errorMsg = '';
  const files = selectedFiles;

  if (!files || files?.length === 0) {
    errorMsg = 'Please select pdf file to upload.';
  } else if (!multiUpload && files?.length > 1) {
    errorMsg = 'Single pdf file selection allowed.';
  } else if (multiUpload) {
    errorMsg = validateAssetLanguageFormat(selectedFiles);
  }

  if (errorMsg === '') {
    const allowedExtensions = /(\.pdf)$/i;
    errorMsg = validateFileType(allowedExtensions, files);
  }

  return errorMsg || null;
};

const validateSelectedDocxFile = (
  selectedFiles = null,
  multiUpload = false
) => {
  let errorMsg = '';
  const files = selectedFiles;

  if (!files || files?.length === 0) {
    errorMsg = 'Please select docx file to upload.';
  } else if (!multiUpload && files?.length > 1) {
    errorMsg = 'Single docx file selection allowed.';
  } else if (multiUpload) {
    errorMsg = validateAssetLanguageFormat(selectedFiles);
  }

  if (errorMsg === '') {
    const allowedExtensions = /(\.docx)$/i;
    errorMsg = validateFileType(allowedExtensions, files);
  }

  return errorMsg || null;
};

export {
  validateSelectedVideoFile,
  validateSelectedImageFile,
  validateSelectedAudioFile,
  validateSelectedPdfFile,
  validateSelectedDocxFile,
};
