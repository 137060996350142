import React from 'react';
import PropTypes from 'prop-types';
import AppLayout from '../../layout/app.layout';
import PAGE_TITLE_LIST from '../../config/page-title.config';
import ListTabs from '../../components/content/list-tabs.component';

const ContentTabsView = ({ rerenderComponent }) => {
  const pageTitle = PAGE_TITLE_LIST.CONTENT_TABS;

  return (
    <AppLayout pageTitle={pageTitle} rerenderComponent={rerenderComponent}>
      <ListTabs rerenderComponent={rerenderComponent} />
    </AppLayout>
  );
};

ContentTabsView.propTypes = {
  rerenderComponent: PropTypes.func.isRequired,
};

export default ContentTabsView;
