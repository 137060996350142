import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from '@mui/material';
import Iconify from '../common/iconify.component';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import ViewData from '../table-elements/view-data.component';
import { viewVideoById } from '../../services/content.service';
import { VIEW_VIDEOS_COLUMNS } from '../../config/module-configs/videos.config';
import { CONTENT_ALIAS } from '../../config/const.config';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const ViewVideos = ({ title, dataId, onCancel, videoContentType }) => {
  const columns = VIEW_VIDEOS_COLUMNS;

  const renderColumns = [];
  columns.forEach((item) => {
    if (videoContentType === 'ads') {
      if (
        item.id !== 'longDescription' &&
        item.id !== 'featuring' &&
        item.id !== 'tags' &&
        item.id !== 'categoryName' &&
        item.id !== 'thumbnail' &&
        item.id !== 'heroImage' &&
        item.id !== 'cardImage'
      ) {
        if (item.id === 'videoUrl') {
          const temp = item;
          temp.label = 'Ad URL';
          renderColumns.push(temp);
        } else {
          renderColumns.push(item);
        }
      }
    } else if (videoContentType === 'videos') {
      if (item.id === 'videoUrl') {
        const temp = item;
        temp.label = `${CONTENT_ALIAS.VIDEO_SINGULAR} URL`;
        renderColumns.push(temp);
      } else {
        renderColumns.push(item);
      }
    }
  });

  const [loading, setLoading] = useState(true);
  const [rowData, setRowData] = useState(null);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  useEffect(() => {
    viewVideoById(dataId)
      .then((res) => {
        const response = res.data;

        const categoryName = response.category.map((item, i) => {
          const catData = `${item.categoryName} (${item.tabName})`;

          return response.category.length === i + 1 ? catData : `${catData}, `;
        });
        if (categoryName) {
          response.categoryName = categoryName;
        }

        setRowData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, [dataId]);

  return (
    <Dialog
      open
      onClose={() => onCancel()}
      aria-labelledby="view-dialog-title"
      aria-describedby="view-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="view-dialog-title">{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="view-dialog-description">
        {loading && <Loading />}

        {!loading && <ViewData columns={renderColumns} row={rowData} />}
      </DialogContent>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

ViewVideos.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  videoContentType: PropTypes.string.isRequired,
};

export default ViewVideos;
