import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@mui/material';
import {
  CONTACT_STATUS_LABELS,
  CONTACT_STATUS_COLORS,
} from '../../config/const.config';

const ContactStatusLabel = ({ value }) => {
  const label = CONTACT_STATUS_LABELS[value] || 'Unknown';
  const color = CONTACT_STATUS_COLORS[value] || 'primary';

  return <Chip label={label} color={color} variant="filled" size="small" />;
};

ContactStatusLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

export default ContactStatusLabel;
