import React from 'react';
import AppLayout from '../../layout/app.layout';
import ListRealtimeLiveUsers from '../../components/reports/list-realtime-live-users.component';
import PAGE_TITLE_LIST from '../../config/page-title.config';

const RealtimeDashboardView = () => {
  const pageTitle = PAGE_TITLE_LIST.REALTIME_DASHBOARD;

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListRealtimeLiveUsers />
    </AppLayout>
  );
};

export default RealtimeDashboardView;
