import React, { forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Slide,
} from '@mui/material';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Iconify from '../common/iconify.component';
import Loading from '../table-elements/loading.component';
import {
  getListSequence,
  updateTabsSequence,
} from '../../services/content.service';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const ListSequenceTabs = ({
  manageTabs,
  setManageTabs,
  snackbarInfo,
  setSnackbarInfo,
  onSuccess,
}) => {
  const handleOnDragEndTabs = (result) => {
    if (result.destination) {
      const newTabs = Array.from(manageTabs.data);
      const [draggedItem] = newTabs.splice(result.source.index, 1);
      newTabs.splice(result.destination.index, 0, draggedItem);

      setManageTabs({
        ...manageTabs,
        data: newTabs,
      });
    }
  };

  const handleCloseDialog = () => {
    setManageTabs({
      ...manageTabs,
      show: false,
      loading: true,
      data: '',
    });
  };

  const handleUpdateTabsSequence = () => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const updatedTabsSequence = manageTabs.data.map((item) => item.id);
    const payload = {
      newSequence: updatedTabsSequence,
    };
    updateTabsSequence(payload)
      .then((res) => {
        if (res?.data?.success) {
          onSuccess('Tabs sequence updated successfully!');
          handleCloseDialog();
        }
      })
      .catch(() => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message: 'Something went wrong.',
        });
      });
  };

  useEffect(() => {
    getListSequence('tabs')
      .then((res) => {
        setManageTabs({
          ...manageTabs,
          data: res.data,
          loading: false,
        });
      })
      .catch(() => {
        // to do
      });
  }, []);

  return (
    <Dialog
      open
      aria-describedby="tabs"
      aria-labelledby="tabs"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>Reorder Tabs Sequence</DialogTitle>

      <DialogContent dividers>
        {manageTabs.loading && <Loading />}

        {!manageTabs.loading && (
          <DragDropContext onDragEnd={handleOnDragEndTabs}>
            <Droppable droppableId="tabs">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {!manageTabs.loading &&
                    manageTabs.data.length > 0 &&
                    manageTabs.data.map((item, index) => (
                      <Draggable
                        key={`tab-${item.id}`}
                        draggableId={item.id.toString()}
                        index={index}
                      >
                        {(provide) => (
                          <Card
                            sx={{
                              marginBottom: '5px',
                            }}
                            ref={provide.innerRef}
                            {...provide.draggableProps}
                            {...provide.dragHandleProps}
                          >
                            <CardContent
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                paddingY: '12px',
                                '&:last-child': {
                                  paddingBottom: '12px',
                                },
                              }}
                            >
                              <div>{item.tabName}</div>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    marginLeft: 10,
                                  }}
                                >
                                  <Iconify icon="ic:round-table-rows" />
                                </div>
                              </div>
                            </CardContent>
                          </Card>
                        )}
                      </Draggable>
                    ))}

                  {!manageTabs.loading && manageTabs.data.length === 0 && (
                    <Paper
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      No data available.
                    </Paper>
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          onClick={handleUpdateTabsSequence}
          disabled={
            manageTabs.data.length === 0 || manageTabs.data.length === 1
          }
        >
          Save
        </Button>
        <Button onClick={handleCloseDialog}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

ListSequenceTabs.propTypes = {
  manageTabs: PropTypes.object.isRequired,
  setManageTabs: PropTypes.func.isRequired,
  snackbarInfo: PropTypes.object.isRequired,
  setSnackbarInfo: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default ListSequenceTabs;
