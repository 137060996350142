// Define platforms list
const PLATFORMS_LIST = [
  {
    id: 'ROKU',
    title: 'Roku',
  },
  {
    id: 'APPLETV',
    title: 'Apple TV',
  },
  {
    id: 'APPLE_MOBILE',
    title: 'Apple Mobile',
  },
  {
    id: 'FIRETV',
    title: 'Fire TV',
  },
  {
    id: 'ANDROIDTV',
    title: 'Android TV',
  },
  {
    id: 'ANDROID_MOBILE',
    title: 'Android Mobile',
  },
  {
    id: 'WORDPRESS',
    title: 'WordPress',
  },
  {
    id: 'SAMSUNGTV',
    title: 'Samsung TV',
  },
  {
    id: 'LGTV',
    title: 'LG TV',
  },
];

export const getPlatformLabel = (val) => {
  let label = '';

  PLATFORMS_LIST.forEach((p) => {
    if (label === '' && p.id === val) {
      label = p.title;
    }
  });

  return label;
};

export const CHART_COLORS = [
  '#fd7f6f',
  '#7eb0d5',
  '#b2e061',
  '#bd7ebe',
  '#ffb55a',
  '#ffee65',
  '#beb9db',
  '#fdcce5',
  '#8bd3c7',
];

export default PLATFORMS_LIST;
