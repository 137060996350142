import CELL_DATA_TYPES from '../cell-types.config';

export const LISTING_COLUMNS = [
  {
    id: 'label',
    label: 'Label',
    width: '5%',
    align: 'center',
    dataKey: 'label',
    sortable: false,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'fileUrl',
    label: 'View File',
    width: '5%',
    align: 'center',
    dataKey: 'fileUrl',
    sortable: false,
    type: CELL_DATA_TYPES.PLAY_TARGET,
  },
  {
    id: 'status',
    label: 'Status',
    width: '10%',
    align: 'center',
    dataKey: 'status',
    sortable: false,
    type: CELL_DATA_TYPES.SWITCH,
  },
  {
    id: 'modifiedAt',
    label: 'Date Added',
    width: '35%',
    align: 'left',
    dataKey: 'modifiedAt',
    sortable: false,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const LISTING_ACTIONS = [];
