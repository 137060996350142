import axios from 'axios';
import { getDeviceId } from '../utils/device-info.util';
import { getLocalValue } from '../utils/local-cache.util';
import { LS_ADMIN_USER_AUTH_TOKEN } from '../config/const.config';

const prepareRequestOptions = (
  url,
  method,
  data = null,
  contentType = false
) => {
  const baseAPIUrl = process.env.REACT_APP_CMS_API_ENDPOINT;
  const uuid = getDeviceId();
  const adminAuth = getLocalValue(LS_ADMIN_USER_AUTH_TOKEN);

  const options = {
    url: `${baseAPIUrl}${url}`,
    method,
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
    headers: {
      'Content-Type': !contentType ? 'application/json' : 'multipart/form-data',
      'X-Req-Device': uuid,
      'X-Admin-Auth-Token': adminAuth,
    },
    data: !contentType ? JSON.stringify(data || {}) : data,
  };

  return options;
};

export const getDataList = async (type) => {
  const options = prepareRequestOptions(`/admin/list/${type}`, 'GET');

  const response = await axios(options);
  return response.data;
};

export const getSearchListChannels = async () => {
  const options = prepareRequestOptions('/admin/channels/search-list', 'GET');

  const response = await axios(options);
  return response.data;
};

export const createNewCategory = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/category/create',
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const viewCategoryById = async (id) => {
  const options = prepareRequestOptions(`/admin/category/${id}/view`, 'GET');

  const response = await axios(options);
  return response.data;
};

export const viewLanguageVideoById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/video/${id}/view/language`,
    'GET'
  );
  const response = await axios(options);
  return response.data;
};

export const languageVideoById = async (id, payload) => {
  const options = prepareRequestOptions(
    `/admin/video/${id}/language`,
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const updateCategoryById = async (id, payload) => {
  const options = prepareRequestOptions(
    `/admin/category/${id}/update`,
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const viewCategoryLanguageById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/category/${id}/view/language`,
    'GET'
  );
  const response = await axios(options);
  return response.data;
};

export const updateCategoryByIdLanguage = async (id, payload) => {
  const options = prepareRequestOptions(
    `/admin/category/${id}/update/language`,
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const deleteCategoryById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/category/${id}/delete`,
    'DELETE'
  );

  const response = await axios(options);
  return response.data;
};

export const getSearchListCategories = async () => {
  const options = prepareRequestOptions('/admin/categories/search-list', 'GET');

  const response = await axios(options);
  return response.data;
};

export const createNewVideo = async (payload) => {
  const options = prepareRequestOptions('/admin/video/create', 'POST', payload);

  const response = await axios(options);
  return response.data;
};

export const viewVideoById = async (id) => {
  const options = prepareRequestOptions(`/admin/video/${id}/view`, 'GET');

  const response = await axios(options);
  return response.data;
};

export const viewVideoAssetById = async (id, type = null) => {
  const options = prepareRequestOptions(
    `/admin/video/${id}/assets?type=${type}`,
    'GET'
  );

  const response = await axios(options);
  return response.data;
};

export const updateVideoById = async (id, payload) => {
  const options = prepareRequestOptions(
    `/admin/video/${id}/update`,
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const deleteVideoById = async (id) => {
  const options = prepareRequestOptions(`/admin/video/${id}/delete`, 'DELETE');

  const response = await axios(options);
  return response.data;
};

export const assignCategoryToVideo = async (id, payload) => {
  const options = prepareRequestOptions(
    `/admin/video/${id}/category/assign`,
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const getSearchListVideos = async () => {
  const options = prepareRequestOptions('/admin/videos/search-list', 'GET');

  const response = await axios(options);
  return response.data;
};

export const syncVideoCDNStatus = async (contentType) => {
  const options = prepareRequestOptions(
    `/admin/video/${contentType}/cdn/sync`,
    'GET'
  );

  const response = await axios(options);
  return response.data;
};

export const getListSequence = async (query) => {
  const options = prepareRequestOptions(`/admin/sequence/list/${query}`, 'GET');

  const response = await axios(options);
  return response.data;
};

export const updateChannelCategorySequence = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/sequence/category/update',
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const updateChannelCategoryVideosSequence = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/sequence/video-category/update',
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const updateTabsSequence = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/sequence/tabs/update',
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const createNewTab = async (payload) => {
  const options = prepareRequestOptions('/admin/tab/create', 'POST', payload);

  const response = await axios(options);
  return response.data;
};

export const viewTabById = async (id) => {
  const options = prepareRequestOptions(`/admin/tab/${id}/view`, 'GET');

  const response = await axios(options);
  return response.data;
};

export const updateTabById = async (id, payload) => {
  const options = prepareRequestOptions(
    `/admin/tab/${id}/update`,
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const deleteTabById = async (id) => {
  const options = prepareRequestOptions(`/admin/tab/${id}/delete`, 'DELETE');

  const response = await axios(options);
  return response.data;
};

export const getPlayableStream = async (uri) => {
  const options = prepareRequestOptions(
    `/admin/get/playable-stream?${uri}`,
    'GET'
  );

  const response = await axios(options);
  return response.data;
};

export const uploadAssetFile = async (payload, bulkUpload = false) => {
  const options = prepareRequestOptions(
    `/admin/upload/file/cdn?bulkUpload=${bulkUpload}`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const uploadVideoToBunnyCDN = async (payload) => {
  const options = prepareRequestOptions(
    `/admin/upload/video/cdn`,
    'POST',
    payload
  );

  const response = await axios(options);
  return response.data;
};

export const uploadImageToBunnyCDN = async (payload) => {
  const options = prepareRequestOptions(
    `/admin/upload/image/cdn`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const toggleStatus = async (payload) => {
  const options = prepareRequestOptions(
    `/admin/${payload.type}/status/toggle`,
    'POST',
    payload
  );
  const response = await axios(options);
  return response.data;
};

export const toggleFeatured = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/video/featured/toggle',
    'POST',
    payload
  );
  const response = await axios(options);
  return response.data;
};
