import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import CountWidget from './count-widget.component';
import { getCounters } from '../../services/reports.service';
import {
  CONTENT_ALIAS,
  DEFAULT_CHANNEL,
  SHOW_CONTENT_CATEGORIES,
  SHOW_CONTENT_VIDEOS,
  SHOW_SUBSCRIBERS,
} from '../../config/const.config';
import ROUTE_PATH from '../../config/routes.config';

const Counters = () => {
  const [counters, setCounters] = useState({
    channels: 0,
    videos: 0,
    ads: 0,
    sessions: 0,
    subscribers: 0,
    categories: 0,
  });

  useEffect(() => {
    getCounters()
      .then((res) => {
        setCounters({
          ...counters,
          channels: res?.data?.channels || 0,
          videos: res?.data?.videos || 0,
          ads: res?.data?.ads || 0,
          sessions: res?.data?.sessions || 0,
          subscribers: res?.data?.subscribers || 0,
          categories: res?.data?.categories || 0,
        });
      })
      .catch(() => {
        // ignore
      });
  }, []);

  return (
    <Grid container spacing={3} mb={3}>
      {!DEFAULT_CHANNEL && (
        <Grid item xs={12} sm={2.4} md={2.4}>
          <CountWidget
            title="Channels"
            total={counters.channels}
            icon="ic:twotone-checklist"
            color="warning"
          />
        </Grid>
      )}

      {SHOW_SUBSCRIBERS && (
        <Grid
          item
          xs={12}
          sm={!DEFAULT_CHANNEL ? 2.4 : 3}
          md={!DEFAULT_CHANNEL ? 2.4 : 3}
        >
          <CountWidget
            title="Subscribers"
            total={counters.subscribers}
            color="error"
            icon="ci:users"
          />
        </Grid>
      )}

      {SHOW_CONTENT_CATEGORIES && (
        <Grid
          item
          xs={12}
          sm={!DEFAULT_CHANNEL ? 2.4 : 3}
          md={!DEFAULT_CHANNEL ? 2.4 : 3}
        >
          <CountWidget
            title={`${CONTENT_ALIAS.CATEGORY_PLURAL}`}
            total={counters.categories}
            color="info"
          />
        </Grid>
      )}

      {SHOW_CONTENT_VIDEOS && (
        <>
          <Grid
            item
            xs={12}
            sm={!DEFAULT_CHANNEL ? 2.4 : 3}
            md={!DEFAULT_CHANNEL ? 2.4 : 3}
          >
            <CountWidget
              title={`${CONTENT_ALIAS.VIDEO_PLURAL}`}
              total={counters.videos}
              color="success"
              icon="octicon:video-16"
              navLink={ROUTE_PATH.CONTENT_VIDEOS}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={!DEFAULT_CHANNEL ? 2.4 : 3}
            md={!DEFAULT_CHANNEL ? 2.4 : 3}
          >
            <CountWidget
              title="Ads"
              total={counters.ads}
              color="warning"
              icon="mdi:advertisements"
              navLink={ROUTE_PATH.CONTENT_ADS}
            />
          </Grid>
        </>
      )}

      <Grid
        item
        xs={12}
        sm={!DEFAULT_CHANNEL ? 2.4 : 3}
        md={!DEFAULT_CHANNEL ? 2.4 : 3}
      >
        <CountWidget
          title="Watch Sessions"
          total={counters.sessions}
          color="error"
          icon="ic:outline-list-alt"
          navLink={ROUTE_PATH.REPORTS_SESSIONS}
        />
      </Grid>
    </Grid>
  );
};

export default Counters;
