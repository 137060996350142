import React from 'react';
import AppLayout from '../../layout/app.layout';
import PAGE_TITLE_LIST from '../../config/page-title.config';
import ListContacts from '../../components/contacts/list-contacts.component';

const ContactsView = () => {
  const pageTitle = PAGE_TITLE_LIST.CONTACTS;

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListContacts />
    </AppLayout>
  );
};

export default ContactsView;
