import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS, CONTENT_ALIAS } from '../const.config';

// Videos module configurations
export const LISTING_COLUMNS = [
  {
    id: 'id',
    label: 'ID',
    width: '5%',
    align: 'center',
    dataKey: 'id',
    sortable: false,
    type: CELL_DATA_TYPES.CODE,
  },
  {
    id: 'thumbnail',
    label: 'Image Preview',
    width: '5%',
    align: 'center',
    dataKey: 'thumbnail',
    sortable: false,
    type: CELL_DATA_TYPES.IMAGE,
  },
  {
    id: 'title',
    label: 'Title',
    width: '15%',
    align: 'left',
    dataKey: 'title',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'duration',
    label: 'Duration',
    width: '10%',
    align: 'right',
    dataKey: 'duration',
    sortable: true,
    type: CELL_DATA_TYPES.DURATION,
  },
  {
    id: 'preview',
    label: '',
    width: '2%',
    align: 'center',
    dataKey: 'preview',
    sortable: false,
    type: CELL_DATA_TYPES.PREVIEW,
  },
  {
    id: 'categoryName',
    label: `${CONTENT_ALIAS.CATEGORY_PLURAL}`,
    width: '20%',
    align: 'left',
    dataKey: 'categoryName',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'isFeatured',
    label: `Featured ${CONTENT_ALIAS.VIDEO_SINGULAR}`,
    width: '10%',
    align: 'center',
    dataKey: 'isFeatured',
    sortable: true,
    type: CELL_DATA_TYPES.SWITCH,
  },
  {
    id: 'status',
    label: 'Status',
    width: '10%',
    align: 'center',
    dataKey: 'status',
    sortable: true,
    type: CELL_DATA_TYPES.SWITCH,
  },
  {
    id: 'adPlaysCount',
    label: 'Viewed last 30 days',
    width: '15%',
    align: 'center',
    dataKey: 'adPlaysCount',
    sortable: false,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'createdAt',
    label: 'Created Date',
    width: '15%',
    align: 'right',
    dataKey: 'createdAt',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const LISTING_ACTIONS = [
  {
    action: ACTIONS.EDIT.value,
    label: ACTIONS.EDIT.label,
  },
  {
    action: ACTIONS.VIEW.value,
    label: ACTIONS.VIEW.label,
  },
  {
    action: ACTIONS.TRANSLATE.value,
    label: ACTIONS.TRANSLATE.label,
  },
  {
    action: ACTIONS.UPLOAD_MP4.value,
    label: ACTIONS.UPLOAD_MP4.label,
  },
  {
    action: ACTIONS.UPLOAD_SLIDES.value,
    label: ACTIONS.UPLOAD_SLIDES.label,
  },
  {
    action: ACTIONS.UPLOAD_PODCASTS.value,
    label: ACTIONS.UPLOAD_PODCASTS.label,
  },
  {
    action: ACTIONS.UPLOAD_TRANSCRIPT_PDF.value,
    label: ACTIONS.UPLOAD_TRANSCRIPT_PDF.label,
  },
  {
    action: ACTIONS.UPLOAD_TRANSCRIPT_DOCX.value,
    label: ACTIONS.UPLOAD_TRANSCRIPT_DOCX.label,
  },
  {
    action: ACTIONS.ASSIGN_CATEGORIES.value,
    label: ACTIONS.ASSIGN_CATEGORIES.label,
  },
  {
    action: ACTIONS.DELETE.value,
    label: ACTIONS.DELETE.label,
  },
];

export const VIEW_VIDEOS_COLUMNS = [
  {
    id: 'title',
    label: 'Title',
    dataKey: 'title',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'description',
    label: 'Description',
    dataKey: 'description',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'longDescription',
    label: 'Long Description',
    dataKey: 'longDescription',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'featuring',
    label: 'Featuring',
    dataKey: 'featuring',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'tags',
    label: 'Tags',
    dataKey: 'tags',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'duration',
    label: 'Duration',
    dataKey: 'duration',
    type: CELL_DATA_TYPES.DURATION,
  },
  {
    id: 'categoryName',
    label: `${CONTENT_ALIAS.CATEGORY_SINGULAR}`,
    dataKey: 'categoryName',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'videoUrl',
    label: `${CONTENT_ALIAS.VIDEO_SINGULAR} URL`,
    dataKey: 'videoUrl',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'videoType',
    label: 'Type',
    dataKey: 'videoType',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'thumbnail',
    label: 'Thumbnail',
    dataKey: 'thumbnail',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'cardImage',
    label: 'Card Image',
    dataKey: 'cardImage',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'status',
    label: 'Status',
    dataKey: 'status',
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'cdnStatus',
    label: 'CDN Status',
    dataKey: 'cdnStatus',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'createdAt',
    label: 'Created Date',
    dataKey: 'createdAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const VIDEOJS_OPTIONS = {
  controls: true,
  preload: 'auto',
  responsive: true,
  controlBar: {
    pictureInPictureToggle: false,
  },
  sources: [
    {
      src: '',
    },
  ],
};
