import React from 'react';
import PropTypes from 'prop-types';
import AppLayout from '../../layout/app.layout';
import ListVideos from '../../components/content/list-videos.component';
import PAGE_TITLE_LIST from '../../config/page-title.config';

const ContentVideosView = ({ videoContentType }) => {
  const pageTitle =
    videoContentType === 'videos'
      ? PAGE_TITLE_LIST.CONTENT_VIDEOS
      : PAGE_TITLE_LIST.CONTENT_ADS;

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListVideos videoContentType={videoContentType} />
    </AppLayout>
  );
};

ContentVideosView.propTypes = {
  videoContentType: PropTypes.string.isRequired,
};

export default ContentVideosView;
