import {
  LS_ADMIN_USER_AUTH_TOKEN,
  LS_ADMIN_USER_NAME,
  LS_ADMIN_USER_EMAIL,
  LS_ADMIN_USER_ROLE,
} from '../config/const.config';

// Local storage values getter/setters
export const setLocalValue = (key, defaultValue = '') =>
  window.localStorage.setItem(key, defaultValue);

export const getLocalValue = (key, defaultValue = '') =>
  window.localStorage.getItem(key) || defaultValue;

export const setLocalValueObj = (key, defaultValue = {}) =>
  window.localStorage.setItem(key, JSON.stringify(defaultValue));

export const getLocalValueObj = (key, defaultValue = {}) =>
  JSON.parse(window.localStorage.getItem(key) || '{}') || defaultValue;

// Admin auth helpers
export const setAdminUserLoggedIn = (payload) => {
  setLocalValue(LS_ADMIN_USER_AUTH_TOKEN, payload.token);
  setLocalValue(LS_ADMIN_USER_NAME, payload.name);
  setLocalValue(LS_ADMIN_USER_EMAIL, payload.email);
  setLocalValue(LS_ADMIN_USER_ROLE, payload.role);
};

export const setAdminUserLoggedOut = () => {
  window.localStorage.removeItem(LS_ADMIN_USER_AUTH_TOKEN);
  window.localStorage.removeItem(LS_ADMIN_USER_NAME);
  window.localStorage.removeItem(LS_ADMIN_USER_EMAIL);
  window.localStorage.removeItem(LS_ADMIN_USER_ROLE);
};

export const checkAdminUserLoggedIn = () =>
  getLocalValue(LS_ADMIN_USER_AUTH_TOKEN) !== '';
