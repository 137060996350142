import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@mui/material';
import {
  MP3_STATUS_LABELS,
  MP3_STATUS_COLORS,
} from '../../config/const.config';

const MP3StatusLabel = ({ value }) => {
  const label = MP3_STATUS_LABELS[value] || 'Unknown';
  const color = MP3_STATUS_COLORS[value] || 'primary';

  return <Chip label={label} color={color} variant="filled" size="small" />;
};

MP3StatusLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

export default MP3StatusLabel;
