import React from 'react';
import PropTypes from 'prop-types';
import { useRoutes, Navigate } from 'react-router-dom';
import ROUTE_PATH from '../config/routes.config';
import LoginView from '../views/auth/login.view';
import DashboardView from '../views/dashboard/dashboard.view';
import RealtimeDashboardView from '../views/dashboard/realtime-dashboard.view';
import ContentChannelsView from '../views/content/channels.view';
import ContentVideosView from '../views/content/videos.view';
import SubscribersView from '../views/subscribers/subscribers.view';
import AdminUsersView from '../views/admin-users/admin-users.view';
import ReportsOverviewView from '../views/reports/overview.view';
import ReportsLiveUsersView from '../views/reports/live-users.view';
import ReportsSessionsView from '../views/reports/sessions.view';
import PageNotFoundView from '../views/errors/not-found.view';
import LogoutView from '../views/auth/logout.view';
import ContentCategoriesView from '../views/content/categories.view';
import ContentSequenceView from '../views/content/sequence.view';
import {
  DEFAULT_CHANNEL,
  SHOW_ADMIN_USERS,
  SHOW_CONTACTS,
  SHOW_CONTENT_CATEGORIES,
  SHOW_CONTENT_VIDEOS,
  SHOW_OVERVIEW_DASHBOARD,
  SHOW_REALTIME_DASHBOARD,
  SHOW_REPORTS_LIVE_USERS,
  SHOW_REPORTS_OVERVIEW_ADS,
  SHOW_REPORTS_OVERVIEW_VIDEOS,
  SHOW_REPORTS_WATCH_SESSIONS,
  SHOW_SUBSCRIBERS,
  SHOW_TABS,
  SUBCONTENT_CATEGORIES,
  SUBCONTENT_SEQUENCE,
} from '../config/const.config';
import AdsReportsOverviewView from '../views/reports/ads-overview.view';
import ContentTabsView from '../views/content/tabs.view';
import ContactsView from '../views/contacts/contacts.view';

const AppRouter = ({ isLoggedIn, categoriesTab, rerenderComponent }) => {
  const routeMappings = [];

  if (isLoggedIn) {
    routeMappings.push({
      path: ROUTE_PATH.LOGIN,
      element: <Navigate to={ROUTE_PATH.DASHBOARD} />,
      exact: true,
    });

    if (SHOW_OVERVIEW_DASHBOARD) {
      routeMappings.push({
        path: ROUTE_PATH.DASHBOARD,
        element: <DashboardView />,
        exact: true,
      });
    }

    if (SHOW_REALTIME_DASHBOARD) {
      routeMappings.push({
        path: ROUTE_PATH.REALTIME_DASHBOARD,
        element: <RealtimeDashboardView />,
        exact: true,
      });
    }

    if (!DEFAULT_CHANNEL) {
      routeMappings.push({
        path: ROUTE_PATH.CONTENT_CHANNELS,
        element: <ContentChannelsView />,
        exact: true,
      });
    }

    if (SHOW_CONTENT_CATEGORIES) {
      if (SUBCONTENT_CATEGORIES) {
        for (let i = 0; i < categoriesTab.length; i += 1) {
          routeMappings.push({
            path:
              categoriesTab[i].status === 1
                ? `${ROUTE_PATH.CONTENT_CATEGORIES}/${categoriesTab[i].slug}`
                : null,
            element: (
              <ContentCategoriesView
                categoryTypeSlug={categoriesTab[i].slug}
                categoryTypeName={categoriesTab[i].name}
                categoryTypeId={categoriesTab[i].id}
              />
            ),
            exact: true,
          });
        }
      } else {
        routeMappings.push({
          path: ROUTE_PATH.CONTENT_CATEGORIES,
          element: <ContentCategoriesView />,
          exact: true,
        });
      }
    }

    if (SHOW_CONTENT_VIDEOS) {
      routeMappings.push({
        path: ROUTE_PATH.CONTENT_VIDEOS,
        element: <ContentVideosView videoContentType="videos" />,
        exact: true,
      });

      routeMappings.push({
        path: ROUTE_PATH.CONTENT_ADS,
        element: <ContentVideosView videoContentType="ads" />,
        exact: true,
      });
    }

    if (DEFAULT_CHANNEL) {
      if (SUBCONTENT_SEQUENCE) {
        for (let i = 0; i < categoriesTab.length; i += 1) {
          routeMappings.push({
            path:
              categoriesTab[i].status === 1
                ? `${ROUTE_PATH.CONTENT_SEQUENCE}/${categoriesTab[i].slug}`
                : null,
            element: (
              <ContentSequenceView
                categoryType={categoriesTab[i].slug}
                categoryName={categoriesTab[i].name}
              />
            ),
            exact: true,
          });
        }
      } else {
        routeMappings.push({
          path: ROUTE_PATH.CONTENT_SEQUENCE,
          element: <ContentSequenceView />,
          exact: true,
        });
      }
    }

    if (SHOW_TABS) {
      routeMappings.push({
        path: ROUTE_PATH.CONTENT_TABS,
        element: <ContentTabsView rerenderComponent={rerenderComponent} />,
        exact: true,
      });
    }

    if (SHOW_CONTACTS) {
      routeMappings.push({
        path: ROUTE_PATH.CONTACTS,
        element: <ContactsView rerenderComponent={rerenderComponent} />,
        exact: true,
      });
    }

    if (SHOW_SUBSCRIBERS) {
      routeMappings.push({
        path: ROUTE_PATH.SUBSCRIBERS,
        element: <SubscribersView />,
        exact: true,
      });
    }

    if (SHOW_ADMIN_USERS) {
      routeMappings.push({
        path: ROUTE_PATH.ADMIN_USERS,
        element: <AdminUsersView />,
        exact: true,
      });
    }

    if (SHOW_REPORTS_OVERVIEW_VIDEOS) {
      routeMappings.push({
        path: ROUTE_PATH.REPORTS_OVERVIEW_VIDEOS,
        element: <ReportsOverviewView />,
        exact: true,
      });
    }

    if (SHOW_REPORTS_OVERVIEW_ADS) {
      routeMappings.push({
        path: ROUTE_PATH.REPORTS_OVERVIEW_ADS,
        element: <AdsReportsOverviewView />,
        exact: true,
      });
    }

    if (SHOW_REPORTS_LIVE_USERS) {
      routeMappings.push({
        path: ROUTE_PATH.REPORTS_LIVE_USERS,
        element: <ReportsLiveUsersView />,
        exact: true,
      });
    }

    if (SHOW_REPORTS_WATCH_SESSIONS) {
      routeMappings.push({
        path: ROUTE_PATH.REPORTS_SESSIONS,
        element: <ReportsSessionsView />,
        exact: true,
      });
    }

    routeMappings.push({
      path: ROUTE_PATH.LOGOUT,
      element: <LogoutView />,
      exact: true,
    });
  }

  routeMappings.push({
    path: '*',
    element: isLoggedIn ? <PageNotFoundView /> : <LoginView />,
  });

  const routes = useRoutes(routeMappings);

  return routes;
};

AppRouter.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  categoriesTab: PropTypes.array.isRequired,
  rerenderComponent: PropTypes.func.isRequired,
};

export default AppRouter;
