import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
} from '@mui/material';

const Type = ({
  id,
  name,
  defaultValue,
  label,
  onChange,
  validOptions,
  sx,
  error,
  categoryTypeId,
}) => (
  <FormControl fullWidth error={error !== ''}>
    <InputLabel id={`field-${id}-select-label`}>{label}</InputLabel>
    <Select
      labelId="demo-simple-select-label"
      id={id}
      name={name}
      value={defaultValue}
      label={label}
      onChange={onChange}
      sx={sx}
      disabled
    >
      <MenuItem key={`${id}-v-${categoryTypeId}`} value={categoryTypeId}>
        {validOptions}
      </MenuItem>
    </Select>
    {error !== '' && <FormHelperText>{error}</FormHelperText>}
  </FormControl>
);

Type.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  validOptions: PropTypes.string.isRequired,
  sx: PropTypes.shape(),
  error: PropTypes.string,
  categoryTypeId: PropTypes.number,
};
Type.defaultProps = {
  sx: {},
  error: '',
  categoryTypeId: null,
};

export default Type;
