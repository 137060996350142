import React from 'react';
import { Box, CircularProgress } from '@mui/material';

const Loading = () => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: 400,
      height: '100%',
    }}
  >
    <CircularProgress disableShrink />
  </Box>
);

export default Loading;
