import numeral from 'numeral';

const result = (format, key = '.00') => {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, '') : format;
};

export const formatNumber = (v) => numeral(v).format();

export const formatCurrency = (v) => {
  const format = v ? numeral(v).format('$0,0.00') : '$0';

  return result(format, '.00');
};

export const formatPercent = (v) => {
  const format = v ? numeral(Number(v) / 100).format('0.00 %') : '0 %';

  return result(format, '.00');
};

export const formatDuration = (v) => {
  const format = v ? numeral(Number(v)).format('00:00:00') : '0:00:00';

  return format;
};
