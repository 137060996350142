import React, { useEffect, useState } from 'react';
import {
  Stack,
  Card,
  CardHeader,
  Box,
  IconButton,
  Button,
} from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import JsFileDownloader from 'js-file-download';
import useChart from '../../hooks/charts.hook';
import { getDate, formatDate } from '../../utils/datetime.util';
import { getAdsReportData } from '../../services/reports.service';
import Datepicker from '../form-elements/datepicker.component';
import Iconify from '../common/iconify.component';
import Ads from '../form-elements/ads.component';
import PLATFORMS_LIST, { CHART_COLORS } from '../../config/platforms.config';

const WatchedAdsChart = () => {
  const [filters, setFilters] = useState({
    startDate: getDate(-7),
    endDate: getDate(0),
    reloadCounter: 0,
    loading: true,
  });
  const [seriesData, setSeriesData] = useState([]);
  const [xLabels, setXLabels] = useState([]);
  const chartOptions = useChart({
    chart: {
      type: 'bar',
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    colors: CHART_COLORS,
    xaxis: {
      type: 'date',
      categories: xLabels,
      title: { text: 'Dates' },
    },
    yaxis: { title: { text: 'Platform wise no. of Ads watched' } },
    legend: {
      position: 'right',
      offsetY: 40,
    },
    fill: {
      opacity: 1,
    },
  });

  const handleAdChange = (v) => {
    setFilters({
      ...filters,
      ad: v,
      reloadCounter: filters.reloadCounter + 1,
    });
  };
  const handleStartDateChange = (v) => {
    if (filters.loading) return;
    setFilters({
      ...filters,
      startDate: v,
      reloadCounter: filters.reloadCounter + 1,
      loading: true,
    });
  };
  const handleEndDateChange = (v) => {
    if (filters.loading) return;
    setFilters({
      ...filters,
      endDate: v,
      reloadCounter: filters.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRefreshData = () => {
    if (filters.loading) return;
    setFilters({
      ...filters,
      reloadCounter: filters.reloadCounter + 1,
      loading: true,
    });
  };

  const downloadReport = () => {
    const startDate = formatDate(filters.startDate, 'YYYY-MM-DD');
    const endDate = formatDate(filters.endDate, 'YYYY-MM-DD');
    let downloadFileName = `analytics_${startDate}_${endDate}`;
    let reportType = 'date_wise_ad_plays';

    if (!filters.platform && filters.combinedPlatforms) {
      reportType = 'date_wise_ad_plays_combined';
    }

    const payload = {
      startDate,
      endDate,
      reportType,
      output: 'csv',
    };

    if (filters.ad?.id) {
      payload.ads = [filters.ad.id];
      downloadFileName += `_with-ad-${filters.ad.title}`;
    }

    getAdsReportData(payload)
      .then((res) => {
        // eslint-disable-next-line no-unused-vars
        const download = new JsFileDownloader(res, `${downloadFileName}.csv`);
      })
      .catch(() => {
        // to do
      });
  };

  useEffect(() => {
    const startDate = formatDate(filters.startDate, 'YYYY-MM-DD');
    const endDate = formatDate(filters.endDate, 'YYYY-MM-DD');

    const payload = {
      startDate,
      endDate,
      reportType: 'date_wise_total_ad_plays_count',
    };

    if (filters.ad?.id) {
      payload.ads = [filters.ad.id];
    }

    getAdsReportData(payload)
      .then((res) => {
        const chartXLabels = [];
        const chartXLabelIndex = {};
        const chartPlatformLabels = [];
        const chartPlatformLabelIndex = {};
        const chartSeriesData = [];

        // Prepare x label index and series data possible length
        res.data.rows.forEach((v) => {
          if (!chartXLabels.includes(v.watchDate)) {
            chartXLabels.push(v.watchDate);
            chartXLabelIndex[v.watchDate] = chartXLabels.length - 1;
          }
          if (!chartPlatformLabels.includes(v.platform)) {
            chartPlatformLabels.push(v.platform);
            chartPlatformLabelIndex[v.platform] =
              chartPlatformLabels.length - 1;
          }
        });

        // Prepare empty dataset
        chartPlatformLabels.forEach((p) => {
          const platform = PLATFORMS_LIST.find((pl) => pl.id === p);
          chartSeriesData.push({
            name: platform.title,
            data: new Array(chartXLabels.length).fill(0),
          });
        });

        // Append chart dataset
        res.data.rows.forEach((v) => {
          const { platform, watchDate } = v;
          const platformIndex = chartPlatformLabelIndex[platform];
          const watchDateIndex = chartXLabelIndex[watchDate];
          chartSeriesData[platformIndex].data[watchDateIndex] += v.totalAdCount;
        });

        setSeriesData(chartSeriesData);
        setXLabels(chartXLabels);

        setFilters({ ...filters, loading: false });
      })
      .catch(() => {
        // TODO
        setFilters({ ...filters, loading: false });
      });
  }, [filters.reloadCounter]);

  return (
    <Card>
      <CardHeader
        title="Watched Ads"
        subheader="Platform wise number of watch ads"
        action={
          <IconButton title="Refresh" onClick={handleRefreshData}>
            <Iconify icon="ic:twotone-refresh" />
          </IconButton>
        }
      />

      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Ads
            id="dr_ad"
            name="dr_ad"
            label="Select Ad"
            defaultValue={filters.ad}
            multiple={false}
            onChange={handleAdChange}
            sx={{ width: '25%', my: 2, mx: 5, pt: 1 }}
          />

          <div>
            <Stack direction="row" alignItems="center">
              <Datepicker
                id="dr_start_date"
                name="dr_start_date"
                label="Start Date"
                defaultValue={filters.startDate}
                onChange={handleStartDateChange}
                sx={{ marginRight: 2, width: 200 }}
                readOnly={filters.loading}
              />
              <Box sx={{ mx: '20px' }}>To</Box>
              <Datepicker
                id="dr_end_date"
                name="dr_end_date"
                label="End Date"
                defaultValue={filters.endDate}
                onChange={handleEndDateChange}
                sx={{ width: 200 }}
                readOnly={filters.loading}
              />
            </Stack>
          </div>
        </Stack>

        <Stack sx={{ marginTop: 2 }}>
          <ReactApexChart
            type="bar"
            series={seriesData}
            options={chartOptions}
            height={364}
          />
        </Stack>

        <Stack direction="row" justifyContent="right">
          <Button
            variant="contained"
            sx={{ my: 2, marginRight: 5 }}
            onClick={downloadReport}
          >
            Download CSV
          </Button>
        </Stack>
      </Box>
    </Card>
  );
};

export default WatchedAdsChart;
