// Define flags
export const DEFAULT_CHANNEL = true;
export const DEFAULT_CHANNEL_VALUE = 1;
export const MULTIPLE_CAT_PER_VIDEO = false;
export const SHOW_OVERVIEW_DASHBOARD = true;
export const SHOW_REALTIME_DASHBOARD = true;
export const SHOW_SUBSCRIBERS = false;
export const SHOW_ADMIN_USERS = false;
export const SHOW_CONTENT_CATEGORIES = true;
export const SUBCONTENT_CATEGORIES = true;
export const SHOW_CONTENT_VIDEOS = true;
export const SUBCONTENT_SEQUENCE = true;
export const SHOW_TABS = true;
export const SHOW_REPORTS_OVERVIEW_VIDEOS = true;
export const SHOW_REPORTS_OVERVIEW_ADS = true;
export const SHOW_REPORTS_LIVE_USERS = true;
export const SHOW_REPORTS_WATCH_SESSIONS = true;
export const SHOW_CONTACTS = true;

// Define commonly used constants
export const LS_ADMIN_USER_AUTH_TOKEN = '__tcls_admin_t';
export const LS_ADMIN_USER_NAME = '__tcls_admin_n';
export const LS_ADMIN_USER_EMAIL = '__tcls_admin_e';
export const LS_ADMIN_USER_ROLE = '__tcls_admin_r';

export const STATUS_LABELS = {
  0: 'Inactive',
  1: 'Active',
  2: 'Deleted',
  5: 'Requested Delete',
  11: 'Pending Verification',
  12: 'Cancellation Applied',
};

export const CONTACT_STATUS_LABELS = {
  0: 'Unsubscribed',
  1: 'Subscribed',
};

export const MP3_STATUS_LABELS = {
  0: 'Not Started',
  1: 'In Progress',
  2: 'Completed',
};

export const STATUS_COLORS = {
  0: 'error',
  1: 'success',
  2: 'error',
  5: 'warning',
  11: 'warning',
  12: 'warning',
};

export const CONTACT_STATUS_COLORS = {
  0: 'warning',
  1: 'success',
};

export const MP3_STATUS_COLORS = {
  0: 'error',
  1: 'warning',
  2: 'success',
};

export const CONTENT_ALIAS = {
  CHANNEL_SINGULAR: 'Channel',
  CHANNEL_PLURAL: 'Channels',
  CATEGORY_SINGULAR: 'Book',
  CATEGORY_PLURAL: 'Books',
  VIDEO_SINGULAR: 'Lesson',
  VIDEO_PLURAL: 'Lessons',
  AD_SINGULAR: 'Ad',
  AD_PLURAL: 'Ads',
};

export const CATEGORY_OPTIONS = {
  old: {
    shorthand: 'old',
    title: 'OLD TESTAMENT STUDY',
    displayText: 'Old Testament',
    path: 'old',
  },
  new: {
    shorthand: 'new',
    title: 'NEW TESTAMENT STUDY',
    displayText: 'New Testament',
    path: 'new',
  },
};

export const ACTIONS = {
  CREATE: {
    value: 'create',
    label: 'Create New',
  },
  EDIT: {
    value: 'update',
    label: 'Edit',
  },
  TRANSLATE: {
    value: 'translate',
    label: 'Translate',
  },
  VIEW: {
    value: 'view',
    label: 'View',
  },
  DELETE: {
    value: 'delete',
    label: 'Delete',
  },
  CHANGE_PASSWORD: {
    value: 'change_password',
    label: 'Change Password',
  },
  ASSIGN_CATEGORIES: {
    value: 'assign_books',
    label: `Assign ${CONTENT_ALIAS.CATEGORY_PLURAL}`,
  },
  UPLOAD_MP4: {
    value: 'upload_mp4',
    label: 'Upload Video (MP4)',
  },
  UPLOAD_SLIDES: {
    value: 'upload_slides',
    label: 'Upload Slides (PDF)',
  },
  UPLOAD_PODCASTS: {
    value: 'upload_podcasts',
    label: 'Upload Audio (MP3)',
  },
  UPLOAD_TRANSCRIPT_PDF: {
    value: 'upload_transcript_pdf',
    label: 'Upload Transcript (PDF)',
  },
  UPLOAD_TRANSCRIPT_DOCX: {
    value: 'upload_transcript_docx',
    label: 'Upload Transcript (Docx)',
  },
};

export const SESSION_TYPE = {
  video: 'Video',
  audio: 'Audio',
};

export const ASSET_TYPE = {
  SLIDES: 'SLIDES',
  PODCAST: 'PODCAST',
  TRANSCRIPT_PDF: 'TRANSCRIPT',
  TRANSCRIPT_DOCX: 'TRANSCRIPT-DOCX',
};

export const DEFAULT_FEATURED_LESSON_ID = 377;

// Place it in CSS file
export const FOCUS_PALETTE_BGCOLOR = 'rgba(255, 255, 255, 0.25)';

export const LANGUAGES = [
  { value: 'es', label: 'Spanish' },
  { value: 'ru', label: 'Russian ' },
  { value: 'ar', label: 'Arabic' },
  // { value: 'hi', label: 'Hindi' },
  // { value: 'zh', label: 'Chinese' },
];
