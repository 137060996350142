import React, { useEffect, useState } from 'react';
import { Stack, Grid, Container, Card, Box } from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import { DEFAULT_CHANNEL } from '../../config/const.config';
import Datepicker from '../form-elements/datepicker.component';
import { getSummary } from '../../services/reports.service';
import { formatDate, getDate } from '../../utils/datetime.util';
import PLATFORMS_LIST, { CHART_COLORS } from '../../config/platforms.config';

const PieChart = () => {
  const [seriesData, setSeriesData] = useState([]);
  const [xLabels, setXLabels] = useState([]);
  const [sessionPCount, setSessionPCount] = useState([]);

  const [filters, setFilters] = useState({
    channel: null,
    startDate: getDate(-7),
    endDate: getDate(0),
    reloadCounter: 0,
    loading: true,
  });
  const optionsSeries = {
    series: seriesData,
    chart: {
      width: 380,
      type: 'pie',
    },
    labels: xLabels,
    colors: CHART_COLORS,
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  };

  const optionsSessPerCount = {
    series: sessionPCount,
    chart: {
      width: 380,
      type: 'pie',
    },
    labels: xLabels,
    colors: CHART_COLORS,
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  };

  const handleStartDateChange = (v) => {
    if (filters.loading) return;
    setFilters({
      ...filters,
      startDate: v,
      reloadCounter: filters.reloadCounter + 1,
      loading: true,
    });
  };
  const handleEndDateChange = (v) => {
    if (filters.loading) return;
    setFilters({
      ...filters,
      endDate: v,
      reloadCounter: filters.reloadCounter + 1,
      loading: true,
    });
  };

  useEffect(() => {
    const startDate = formatDate(filters.startDate, 'YYYY-MM-DD');
    const endDate = formatDate(filters.endDate, 'YYYY-MM-DD');

    getSummary(startDate, endDate)
      .then((res) => {
        const le = [];
        const platform = [];
        const sC = [];
        res.data.map((pd) => le.push(parseFloat(pd.durationPercentage)));
        res.data.map((pd) =>
          platform.push(PLATFORMS_LIST.find((p) => p.id === pd.platform).title)
        );
        res.data.map((pd) => sC.push(parseFloat(pd.sessionPercentageCount)));

        setSeriesData(le);
        setSessionPCount(sC);
        setXLabels(platform);

        setFilters({ ...filters, loading: false });
      })
      .catch(() => {
        // TODO
        setFilters({ ...filters, loading: false });
      });
  }, [filters.reloadCounter]);

  return (
    <Box className="donut" sx={{ pt: 3 }}>
      <Card>
        <Grid item xs={!DEFAULT_CHANNEL ? 6 : 12} sx={{ p: 3, pb: 1 }}>
          <Stack direction="row" justifyContent="right">
            <Grid sx={{ marginRight: 2, width: 200 }}>
              <Datepicker
                id="dr_start_date"
                name="dr_start_date"
                label="Start Date"
                defaultValue={filters.startDate}
                onChange={handleStartDateChange}
                sx={{ marginRight: 2, width: 200 }}
                readOnly={filters.loading}
              />
            </Grid>
            <Grid sx={{ width: 200 }}>
              <Datepicker
                id="dr_end_date"
                name="dr_end_date"
                label="End Date"
                defaultValue={filters.endDate}
                onChange={handleEndDateChange}
                sx={{ width: 200 }}
                readOnly={filters.loading}
              />
            </Grid>
          </Stack>
        </Grid>

        {seriesData.length <= 0 && (
          <Grid
            direction="row"
            maxWidth="lg"
            height={400}
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <div style={{ fontWeight: 700 }}>
              No reports available for Lesson play duration and session count
              summary per platform.
            </div>
          </Grid>
        )}

        {seriesData.length > 0 && (
          <Container maxWidth="lg">
            <Grid container>
              <Box sx={{ p: 3, pb: 1 }} dir="ltr">
                <Stack direction="row" justifyContent="right">
                  <Grid container>
                    <ReactApexChart
                      type="pie"
                      series={seriesData}
                      options={optionsSeries}
                      height={800}
                      width={500}
                    />
                    <div style={{ fontWeight: 700 }}>
                      Lesson Play Duration Summary per Platform
                    </div>
                  </Grid>
                  <Grid container>
                    <ReactApexChart
                      type="pie"
                      series={sessionPCount}
                      options={optionsSessPerCount}
                      height={800}
                      width={500}
                    />
                    <div style={{ fontWeight: 700 }}>
                      Lesson Play Session count Summary per Platform
                    </div>
                  </Grid>
                </Stack>
              </Box>
            </Grid>
          </Container>
        )}

        {/* {seriesData.length === 0 && (
          <Container maxWidth="lg">
            <Grid container>
              <Box sx={{ p: 3, pb: 1 }} dir="ltr">
                <Stack direction="row" justifyContent="right">
                  <Grid container>
                    <div style={{ fontWeight: 700 }}>No reports available.</div>
                  </Grid>
                </Stack>
              </Box>
            </Grid>
          </Container>
        )} */}
      </Card>
    </Box>
  );
};

export default PieChart;
