import CELL_DATA_TYPES from '../cell-types.config';

// Channels module configurations
export const LISTING_COLUMNS = [
  {
    id: 'email',
    label: 'Email',
    width: '15%',
    align: 'left',
    dataKey: 'email',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'firstName',
    label: 'First Name',
    width: '15%',
    align: 'center',
    dataKey: 'firstName',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'lastName',
    label: 'Last Name',
    width: '15%',
    align: 'center',
    dataKey: 'lastName',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'status',
    label: 'Status',
    width: '15%',
    align: 'center',
    dataKey: 'status',
    sortable: true,
    type: CELL_DATA_TYPES.CONTACT_STATUS,
  },
  {
    id: 'createdAt',
    label: 'Created Date',
    width: '15%',
    align: 'right',
    dataKey: 'createdAt',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'modifiedAt',
    label: 'Modified Date',
    width: '15%',
    align: 'right',
    dataKey: 'modifiedAt',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const LISTING_ACTIONS = [];
