import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
} from '@mui/material';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const ConfirmPopup = ({
  title,
  message,
  onCancel,
  onSuccess,
  successButtonText,
  cancelButtonText,
}) => {
  const [processing, setProcessing] = useState(false);

  const handleApiCall = () => {
    setProcessing(true);
    onSuccess();
  };

  return (
    <Dialog
      open
      onClose={() => onCancel()}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
      fullWidth
      TransitionComponent={Transition}
    >
      <DialogTitle id="confirm-dialog-title">{title}</DialogTitle>
      <DialogContent id="confirm-dialog-description">{message}</DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button
          color="error"
          variant="contained"
          onClick={() => handleApiCall()}
          disabled={processing}
        >
          {successButtonText}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={onCancel}
          disabled={processing}
        >
          {cancelButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmPopup.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  successButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
};
ConfirmPopup.defaultProps = {
  successButtonText: 'Yes',
  cancelButtonText: 'No',
};

export default ConfirmPopup;
