import React, { useState } from 'react';
import {
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Button,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import ROUTE_PATH from '../../config/routes.config';
import { setAdminUserLoggedIn } from '../../utils/local-cache.util';
import validationRules from '../../utils/validations.util';
import { getDeviceOs, getDeviceModel } from '../../utils/device-info.util';
import Iconify from '../common/iconify.component';
import SnackbarInfo from '../common/snackbar-info.component';
import { adminUserLogin } from '../../services/auth.service';
import { APP_THEME } from '../../theme/theme-colors';

const Login = () => {
  const {
    control,
    resetField,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      username: '',
      password: '',
    },
  });

  const [showPassword, setShowPassword] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const payload = {
      username: data.username.replace(/\s+/g, ' ').trim(),
      password: data.password,
      deviceDetails: {
        os: getDeviceOs(),
        model: getDeviceModel(),
      },
    };
    adminUserLogin(payload)
      .then((res) => {
        const loggedInUserPayload = {
          token: res.data?.auth?.token || '',
          name: res.data?.name || '',
          email: res.data?.email || '',
          role: res.data?.role || '',
        };
        setAdminUserLoggedIn(loggedInUserPayload);

        window.location.href = ROUTE_PATH.DASHBOARD;
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        resetField('password');
        setFormSubmitted(false);
      });
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <Stack spacing={3} sx={{ my: 4 }}>
        <Controller
          name="username"
          control={control}
          rules={validationRules.REQUIRED}
          render={({ field }) => (
            <TextField
              {...field}
              label="Email"
              error={!!errors.username}
              helperText={errors?.username?.message || null}
            />
          )}
        />

        <Controller
          name="password"
          control={control}
          rules={validationRules.REQUIRED}
          render={({ field }) => (
            <TextField
              {...field}
              label="Password"
              type={showPassword ? 'text' : 'password'}
              error={!!errors.password}
              helperText={errors?.password?.message || null}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      <Iconify
                        icon={
                          showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </Stack>

      <Button
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        disabled={formSubmitted}
        sx={{ bgcolor: APP_THEME }}
      >
        Login
      </Button>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </form>
  );
};

export default Login;
